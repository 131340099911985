import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"

import bloglist from "../content/images/bloglist.png"

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';


const SinglePost = ({ data }) => {
  const fm = data.mdx.frontmatter;
  const description = fm.seoDescription;
  const imagePath = fm.featuredImage && fm.featuredImage.childImageSharp.fixed.src
  deckDeckGoHighlightElement();
  return (<Layout>
    <SEO title={fm.title} ogImage={imagePath} description={description}/>
      <div className="blog-container">
        <h1 style={{
          fontSize: '2.5em'
        }}>{fm.title}</h1>
        <p className="small-font italic">{fm.displayDate}</p>
        <p style={{
          borderLeft: '3px solid rgba(173, 130, 188, 0.4)',
          padding: '1em 1em 1em 2em',
          margin: '4em 2em',
          backgroundColor: 'rgba(173, 130, 188, 0.1)'
        }}>{fm.summary}</p>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
        <div style={{ textAlign: 'center'}}>
        <img
          src={bloglist}
          style={{
            maxWidth: 300
          }}
          alt = "profile">
          </img>
          <br></br>
          <Link to="/#blog" className="small-font"> Back to blog list</Link>
        </div>
      </div>
    </Layout>)
  }

  export default SinglePost;

  export const pageQuery = graphql`
    query SinglePostQuery($id: String!) {
      mdx(id: {eq: $id}) {
        body
        frontmatter {
          slug
          summary
          seoDescription
          title
          displayDate
          featuredImage {
            childImageSharp {
              fixed (width: 1000) {
                src
              }
            }
          }
        }
      }
    }
  `